<template>
  <div class="explore-page">
    <!-- <top-box :option="1"></top-box> -->
    <div class="bannar">
      <!-- <img src="../../image/bg.png"> -->
      <!-- <img :src="bannerSrc"> -->
      <img :src="bannerSrc" v-if="bannerType" />
      <video
        v-else
        muted="muted"
        id="startvideo"
        class="video"
        :src="bannerSrc"
        type="video/mp4"
        autoplay="autoplay"
        controls="controls"
        loop="-1"
      >
        <p>你的浏览器不支持video标签.</p>
      </video>
      <div class="overlay"></div>
      <div class="bannar-info">
        <div class="bannar-title">赛道探索</div>
        <div class="bannar-detail">{{ bannerTitle }}</div>
      </div>
    </div>
    <main>
      <div class="box" v-for="(item, index) in trackList" :key="index">
        <div v-if="index % 2 == 0" class="main-item">
          <!-- <img src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/e4e3c2ed8c3d41a9bfcd1a41abbc4818_mergeImage.png"> -->
          <div class="main-item-img">
            <img :src="item.src" :title="item.name" />
          </div>
          <div class="main-item-info">
            <div class="title">{{ item.name }}</div>
            <div class="content">
              {{ item.sketch }}
            </div>
            <div class="detail" @click="detailsClick(item.id)">了解详情</div>
          </div>
        </div>
        <div v-else class="main-item">
          <div class="main-item-info">
            <div class="title">{{ item.name }}</div>
            <div class="content">
              {{ item.sketch }}
            </div>
            <div class="detail" @click="detailsClick(item.id)">了解详情</div>
          </div>
          <div class="main-item-img">
            <img :src="item.src" :title="item.name" />
          </div>
          <!-- <img src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/e4e3c2ed8c3d41a9bfcd1a41abbc4818_mergeImage.png"> -->
        </div>
      </div>
      <div class="empty" v-if="trackList.length == 0">
        <div class="empty-bg"></div>
        <div class="empty-content">暂无数据~</div>
      </div>
    </main>
    <!-- <bottom-box></bottom-box> -->
  </div>
</template>
<script>
import { getBanner, getTrack } from "../../../api/homeApi";
export default {
  data() {
    return {
      bannerSrc: "",
      bannerType: 1,
      bannerTitle: "",
      trackList: [],
    };
  },
  created() {
    this.getBanner();
    this.getTrack();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      );
      return flag;
    },
    getBanner() {
      getBanner({
        plate: 1,
      }).then((res) => {
        if (res.code == 0) {
          if (res.infos.length > 0) {
            if (this._isMobile()) {
              this.bannerType = 1;
              this.bannerSrc = this.formatImageUrl(
                JSON.parse(res.infos[0].mobileImage)[0].raw
              );
            } else {
              let arr = JSON.parse(res.infos[0].image);
              if (
                [
                  "image/png", // .png
                  "image/jpeg", // .jpg .jpeg
                  "image/x-ms-bmp", // .bmp
                  "image/gif", // .gif
                  "image/tiff", // .tif .tiff
                ].includes(arr[0].type)
              ) {
                this.bannerType = 1;
              } else {
                this.bannerType = 0;
              }
              this.bannerSrc = this.formatImageUrl(
                JSON.parse(res.infos[0].image)[0].raw
              );
            }
            this.bannerTitle = res.infos[0].content;
          }
        }
      });
    },
    getTrack() {
      getTrack({
        page: 1,
        limit: 4,
      }).then((res) => {
        if (res.code == 0) {
          this.trackList = res.page.list.map((item) => {
            item.src = this.formatImageUrl(JSON.parse(item.image)[0].raw);
            let str = item.details
              .replace(/<[^<>]+>/g, "")
              .replace(/&nbsp;/gi, "");
            item.detail = str;
            return item;
          });
        }
      });
    },
    detailsClick(id) {
      this.$router.push({ path: `/explore-details/${id}` });
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and(max-width: 900px) {
  .explore-page {
    .bannar {
      width: 100%;
      // height: 5.5rem;
      background: #666;
      margin-top: 20px;
      position: relative;
      .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 9;
      }
      img {
        // height: 100%;
        vertical-align: middle;
        width: 100%;
        height: 30vw;
      }
      .bannar-info {
        position: absolute;
        top: 20%;
        left: 0;
        width: 100%;
        text-align: center;
        padding: 0 20px;
        .bannar-title {
          font-size: 36px;
          color: #fff;
          margin-bottom: 20px;
        }
        .bannar-detail {
          font-size: 24px;
          color: #fff;
        }
      }
    }
    main {
      width: 100%;
      box-sizing: border-box;
      padding: 0.8rem 0rem;
      background-color: #fff;
      // width: 1200px;
      margin: 0 auto;
      .box {
        margin-bottom: 0.8rem;
      }
      .box:nth-last-child(1) {
        margin-bottom: 0;
      }
      .main-item {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .main-item-img {
          width: 100%;
          height: 3.8rem;
          flex-shrink: 0;
        }
        img {
          width: 100%;
          height: 3.8rem;
          display: block;
        }
        .main-item-info {
          border: 1px solid #e6e6e6;
          padding: 0.5rem;
          box-sizing: border-box;
          flex: 1;
          .title {
            font-size: 24px;
            color: #333;
            margin-bottom: 0.2rem;
          }
          .content {
            font-size: 18px;
            color: #666;
            line-height: 2;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 0.6rem;
          }
          .detail {
            cursor: pointer;
            font-size: 18px;
            color: #333;
            padding: 0 0.3rem;
            position: relative;
            width: max-content;
            height: 0.26rem;
            line-height: 0.26rem;
          }
          .detail::after {
            content: "";
            position: absolute;
            height: 0.56rem;
            width: 0.02rem;
            background-color: #ee6a23;
            left: 0;
            top: -0.15rem;
          }
          .detail::before {
            content: "";
            position: absolute;
            height: 0.56rem;
            width: 0.02rem;
            background-color: #ee6a23;
            right: 0;
            top: -0.15rem;
          }
        }
      }
    }
  }
}
@media screen and(min-width: 900px) {
  .explore-page {
    .bannar {
      width: 100%;
      // height: 5.5rem;
      background: #666;
      position: relative;
      img {
        // height: 100%;
        vertical-align: middle;
        width: 100%;
        height: 550px;
      }
      .bannar-info {
        position: absolute;
        top: 40%;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 9;
        .bannar-title {
          font-size: 36px;
          color: #fff;
          margin-bottom: 20px;
        }
        .bannar-detail {
          font-size: 24px;
          color: #fff;
        }
      }
    }
    main {
      width: 100%;
      box-sizing: border-box;
      padding: 0.8rem 0rem;
      background-color: #fff;
      width: 1200px;
      margin: 0 auto;
      .box {
        margin-bottom: 0.8rem;
      }
      .box:nth-last-child(1) {
        margin-bottom: 0;
      }
      .main-item {
        width: 100%;
        display: flex;
        .main-item-img {
          width: 6rem;
          height: 3.8rem;
          flex-shrink: 0;
          overflow: hidden;
          img {
            width: 6rem;
            height: 3.8rem;
            transition: 0.2s all;
          }
          img:hover {
            transform: scale(1.1);
            transition: 0.2s all;
          }
        }

        .main-item-info {
          border: 1px solid #e6e6e6;
          padding: 0.5rem;
          box-sizing: border-box;
          flex: 1;
          .title {
            font-size: 0.26rem;
            color: #333;
            margin-bottom: 0.2rem;
          }
          .content {
            font-size: 0.12rem;
            color: #666;
            line-height: 2;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 0.6rem;
          }
          .detail {
            cursor: pointer;
            font-size: 0.18rem;
            color: #333;
            padding: 20px 0.3rem;
            position: relative;
            width: max-content;
            // height: .26rem;
            // line-height: .26rem;
            border-left: 2px solid #ee6a23;
            border-right: 2px solid #ee6a23;
            border-top: 2px solid transparent;
            border-bottom: 2px solid transparent;
            transition: 0.2s all;
          }
          .detail:hover {
            border-top: 2px solid #ee6a23;
            border-bottom: 2px solid #ee6a23;
          }
          // .detail::after {
          //     content: '';
          //     position: absolute;
          //     height: .56rem;
          //     width: .02rem;
          //     background-color: #EE6A23;
          //     left: 0;
          //     top: -.15rem;
          // }
          // .detail::before {
          //     content: '';
          //     position: absolute;
          //     height: .56rem;
          //     width: .02rem;
          //     background-color: #EE6A23;
          //     right: 0;
          //     top: -.15rem;
          // }
        }
      }
    }
  }
}
</style>